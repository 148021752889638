<template>
  <v-app-bar app dark style="z-index: 2000">
    <v-btn
      v-if="!isLoginScreen"
      @click="toggleMenu()"
      class="mr-2"
      :disabled="showDailyActivity === true"
    >
      <v-icon large :color="mainMenuActive ? colorTheme.icon : ''">
        mdi-menu
      </v-icon>
    </v-btn>
    <router-link
      v-if="showDailyActivity === false"
      @click.native="reloadHomePage"
      to="/"
      tabindex="-1"
    >
      <SmartXLogo />
    </router-link>
    <span v-else> <SmartXLogo /> </span>
    <v-toolbar-title> Billing Entity and Fee Management </v-toolbar-title>
    <v-spacer></v-spacer>
    <div v-if="showWarningBanner" class="mr-15 warning-banner">
      <warning-banner-component></warning-banner-component>
    </div>
    <div v-if="showInfoBanner" class="mr-15 info-banner">
      <info-banner-component></info-banner-component>
    </div>
    <transition name="slide-fade">
      <div class="welcome-message" v-show="showWelcomeMessage">
        <span :style="{ color: 'rgba(255, 255, 255, 0.4)' }">Welcome, </span>
        <span>
          <b>{{ username }}</b>
        </span>
      </div>
    </transition>
    <v-icon v-if="showWarningIcon">
      mdi-alert
    </v-icon>
    <v-toolbar-title v-if="!this.appNavigationBanner" class="ml-2 env-info">
      {{ environmentInfoText }}
    </v-toolbar-title>
    <v-toolbar-title v-else class="ml-2 banner-font">
      {{ environmentInfoText }}
    </v-toolbar-title>
    <div v-if="showBackgroundActivityBox" class="background-activity-dropdown ml-4">
      <div style="display: flex">
        <div>
          <v-icon @click="backgroundActivityBox" class="after-disabled">mdi-clock</v-icon>
        </div>
        <div style="font-size: 12px" class="pt-1 pl-5">Background Activity</div>
      </div>
      <div>
        <v-icon @click="showBackgroundActivityCard" class="after-disabled">mdi-menu-down</v-icon>
      </div>
    </div>
    <v-btn
      v-show="showBackgroundActivity"
      v-else
      icon
      dark
      @click="backgroundActivityBox"
      :disabled="showDailyActivity === true"
      class="before-disabled"
    >
      <v-icon class="after-disabled">mdi-clock-outline</v-icon>
    </v-btn>
    <div v-if="backgroundActivityCard">
      <background-activity-card></background-activity-card>
    </div>
    <v-btn
      v-show="!isLoginScreen"
      @click="openDownloadPortal"
      icon
      dark
      :disabled="showDailyActivity === true"
      class="before-disabled"
    >
      <v-icon class="after-disabled">mdi-file</v-icon>
    </v-btn>
    <v-menu
      :disabled="isLoginScreen"
      v-model="menu"
      eager
      offset-y
      open-on-hover
      :close-delay="1000"
      :value="!isLoginScreen"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="!isLoginScreen"
          icon
          dark
          v-bind="attrs"
          v-on="on"
          class="before-disabled"
        >
          <v-icon large class="after-disabled">mdi-account-circle</v-icon>
        </v-btn>
      </template>
      <v-list min-width="200">
        <v-list-item
          v-for="(item, index) in menuList"
          :key="index"
          @click="item.action"
          :disabled="disabledListItem(item)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { finishedWithinLastXSeconds } from "@/utils/format-util.js";
import { useBackgroundActivity } from "@/config/organization-config.js";
import SmartXLogo from "./SmartXLogo.vue";
import { isProduction } from "@/config/organization-config.js";
import BackgroundActivityCard from "@/components/background-activity-messages/BackgroundActivityCard.vue";
import WarningBannerComponent from "@/components/background-activity-messages/WarningBannerComponent.vue";
import InfoBannerComponent from "@/components/background-activity-messages/InfoBannerComponent.vue";

export default {
  components: {
    SmartXLogo,
    BackgroundActivityCard,
    WarningBannerComponent,
    InfoBannerComponent
  },
  data: () => ({
    menu: false,
    mainMenu: false,
    activeButton: false,
    backgroundActivityCard: false,
    showWelcomeMessage: false,
    showBackgroundActivityBox: false,
  }),
  computed: {
    ...mapState("appNavigation", ["mainMenuActive", "currentApiEnvironment"]),
    ...mapState("userConfig", ["colorTheme"]),
    ...mapState("auth", ["user"]),
    ...mapState("hierarchyMode", ["hierarchyTag", "hierarchyTags"]),
    ...mapState("backgroundMessages", [
      "appNavigationBanner",
      "timeLeft",
      "infoBanner",
      "showDailyActivity",
      "latestActivity"
    ]),
    username() {
      if (this.user) {
        return this.user.username;
      } else {
        return "";
      }
    },
    showBackgroundActivity() {
      return (
        !this.isLoginScreen &&
        this.user &&
        useBackgroundActivity(this.user.organization)
      );
    },
    showWarningIcon() {
      return isProduction(this.currentApiEnvironment);
    },
    showWarningBanner() {
      if (
        this.timeLeft !== null &&
        this.timeLeft !== "COMPLETED" &&
        this.timeLeft.hours < 1 &&
        this.timeLeft.minutes <= 15 &&
        this.showDailyActivity === false
      ) {
        return true;
      }
      return false;
    },
    showInfoBanner() {
      return (
        this.latestActivity &&
        this.latestActivity.status === "Succeeded" &&
        finishedWithinLastXSeconds(this.latestActivity.finishedAt, 600) && //10 minutes
        this.infoBanner &&
        this.showDailyActivity === false
      );
    },
    environmentInfoText() {
      if (!this.currentApiEnvironment) {
        return "";
      }
      if (isProduction(this.currentApiEnvironment)) {
        return "Production Environment";
      } else {
        return this.currentApiEnvironment + " Environment";
      }
    },
    currentRouteName() {
      return this.$route.name;
    },
    isLoginScreen() {
      if (
        this.currentRouteName === "Authentication" ||
        this.currentRouteName === "ForgotPassword"
      ) {
        return true;
      } else {
        return false;
      }
    },
    menuList() {
      let menu = [
        {
          title: "Profile",
          action: () => this.openProfile(),
        },
        {
          title: "Change password",
          action: () => this.changePassword(),
        },
        {
          title: "Sign out",
          action: () => this.signOut(),
        },
      ];
      return menu;
    },
  },
  watch: {
    isLoginScreen() {
      if (!this.isLoginScreen) {
        this.showWelcomeMessage = true;
        setTimeout(() => {
          this.showWelcomeMessage = false;
        }, 4000);
      }
    },
    showDailyActivity(val) {
      if (val) {
        this.showBackgroundActivityBox = false;
        this.backgroundActivityCard = false;
      }
    },
    $route(to, from) {
      if (from !== to) {
        this.showBackgroundActivityBox = false;
        this.backgroundActivityCard = false;
      }
    }
  },
  methods: {
    ...mapMutations("appNavigation", ["setMainMenuActive"]),
    ...mapActions("auth", ["signOut"]),
    ...mapActions("entityActions", ["setTab"]),
    ...mapActions("backgroundMessages", ["getLatestActivity"]),
    toggleMenu() {
      this.setMainMenuActive(!this.mainMenuActive);
    },
    openProfile() {
      this.$router.push({ name: "Profile" });
    },
    changePassword() {
      this.$router.push({ name: "ProfileChangePassword" });
    },
    openDownloadPortal() {
      this.$router.push({ name: "DownloadPortal" });
    },
    backgroundActivityBox() {
      this.showBackgroundActivityBox = !this.showBackgroundActivityBox;
      this.backgroundActivityCard = false;
    },
    async showBackgroundActivityCard() {
      await this.getLatestActivity();
      this.backgroundActivityCard = !this.backgroundActivityCard;
    },
    reloadHomePage() {
      this.$router.push({
        name: "HierarchyTree",
        params: {
          hierarchy: this.hierarchyTag.address,
          id: "root"
        }
      });
    },
    disabledListItem(item) {
      return this.showDailyActivity === true && item.title != "Sign out";
    },
  },
};
</script>

<style scoped>
.banner-font {
  font-size: 12px;
}
.welcome-message {
  margin-right: 1rem;
}

.slide-fade-enter-active {
  transition: opacity 0.5s ease;
}
.slide-fade-leave-active {
  transition: opacity 0.2s;
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}
.env-info {
  font-size: 14pt;
}
.background-activity-dropdown {
  display: flex;
  justify-content: space-between;
  width: 221px;
  padding: 6px 8px 6px 8px;
  border-radius: 2px;
  background-color: #009688;
  display: flex;
  gap: 8px;
}
.warning-banner {
  position: absolute;
  display: flex;
  border-radius: 4px;
  border: 1px solid #BC5D00;
  background:  #DB8C3E;
  height: 56px;
  width: 580px;
  left: 40%;
}
.info-banner {
  position: absolute;
  display: flex;
  border-radius: 4px;
  background: #CCE1DE;
  height: 56px;
  width: 420px;
  left: 45%;
}
</style>
